<template>
  <div class="video_detail">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item :to="{ path: '/resources' }">资源圈</el-breadcrumb-item>
      <el-breadcrumb-item>视频详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="" class="video-container flex-layout">
      <div class="video-wrapper">
        <video v-if="videoType" controls id="my-video-detail" style="width:100%;height:100%" class="video-js">
        </video>
        <video v-else controls id="video-detail" :src="videoSrc" controlsList="nodownload" autoplay muted></video>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import videojs from 'video.js'
  import 'videojs-contrib-hls'
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        options: {
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: 'auto', // 预加载
          controls: true // 显示播放的控件
        },
        player: null, // 🍉播放器实例对象
        videoSrc: '', //视频地址
        videoType: false,
        info: {}, //课节信息
      };
    },
    computed: {},
    methods: {
      getVideo(nowPlayVideoUrl) {
        this.player = videojs('my-video-detail', this.options)
        this.player.src([{
          src: nowPlayVideoUrl, // 地址
          type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
        }])
      },
      // 新增加函数
      async getDownloadsUrl() {
        let id = this.$route.query.id;
        let videoSrc = `${this.getDownloadUrl}${id}&type=5`
        axios({
          url: videoSrc,
          method: "get",
        }).then((res) => {
          if (res.data.data.ext === 'm3u8') {
            this.videoType = true;
            this.videoSrc = res.data.data.url;
            setTimeout(() => {
              this.getVideo(this.videoSrc)
            }, 100)
          }
          if (res.data.data.ext !== 'm3u8') {
            this.videoType = false;
            this.videoSrc = res.data.data.url;
            let _video = document.getElementById("video-detail");
            setTimeout(() => {
              _video.muted = false;
            }, 1000)
          }
        }).catch(function (error) {
          console.log(error);
        });

      },
      // 页面初始化
      init() {
        this.getDownloadsUrl();
      }
    },
    activated() {},
    deactivated() {},
    mounted() {
      this.init();
    },
    destroyed() {
      this.player.dispose()
    }
  };
</script>

<style lang="less" scoped>
  .video_detail {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // padding: 0;
  }

  .bread-crumb {
    margin: 0px auto;
    width: 1200px;
  }

  .video-container {
    width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 246px);
  }

  .video-wrapper {
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      // max-width: 1300px;
      background-color: #000;
    }
  }

  .title {
    text-align: center;
    height: 2%;
  }

  .video-sidebar {
    padding: 0 20px;
    width: 20%;
    background-color: #282933;
    flex-shrink: 0;

    .group {
      margin-top: 20px;

      &:first-child {
        margin-top: 15px;
      }
    }


    h3 {
      line-height: 36px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;

      &::before {
        content: ' ';
        display: inline-block;
        border-radius: 25%;
        width: 4px;
        height: 20px;
        background-color: #218AB8;
        margin-right: 10px;
        margin-bottom: -5px;
      }
    }

    p {
      color: #fff;
      padding: 0 15px;
    }

    .list /deep/.el-button--primary {
      margin: 20px 15px 0;
      color: #218AB8;
      background-color: transparent;
      border-color: #218AB8;
      padding: 12px 18px;
    }


    .time p {
      font-size: 14px;
      margin: 5px 0 20px;
    }

    .problem {
      .options {
        margin-bottom: 25px;

        /deep/.el-radio {
          display: block;
          margin: 10px 25px;
          color: #fff;
        }
      }

      /deep/.el-button--primary {
        width: 70%;
        display: block;
        margin: 0 auto;
      }

      /deep/.el-button--primary.is-plain {
        color: #fff;
        border-color: #409EFF;
        background-color: #409EFF;

        &:hover {
          background-color: #66b1ff;
          border-color: #66b1ff;
        }
      }
    }

  }


  iframe {
    margin: 0;
    padding: 0;
  }
</style>